<template>
  <v-container fluid>
    <v-form>
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-database-cog"
            inline
            class="px-5 py-3"
            style="min-height: 850px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Oracle :: dados para conexão
              </div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Nome do Servidor"
                >
                  <v-text-field
                    label="Nome do Servidor"
                    v-model="servidor.nome"
                    outlined
                    maxlength="100"
                    autofocus
                    clearable
                    :placeholder="`Exemplo: BDGD_${userLoggedCompany.acronym}`"
                    hint="Um nome para identificar o servidor na aplicação"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <select-company
                  :type="'outlined'"
                  :selectedCompany="userLoggedCompany"
                  @companySelected="companySelected"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-textarea
                    label="TNS Config"
                    v-model="servidor.tnsConfig"
                    rows="10"
                    no-resize
                    clearable
                    background-color="#EEEEEE"
                    placeholder="(DESCRIPTION = (ADDRESS = (PROTOCOL = TCP)(HOST = ?)(PORT = 1521))(CONNECT_DATA = (SERVICE_NAME = ?)))"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Username"
                  v-model="servidor.username"
                  outlined
                  maxlength="100"
                  clearable
                  autocomplete="off"
                  hint="O usuário precisa ter acesso de leitura as views e tabelas da BDGD"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Password"
                  v-model="servidor.password"
                  outlined
                  maxlength="255"
                  autocomplete="off"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  hint="A senha de acesso ao banco de dados"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  class="pt-0 mt-0"
                  label="Standby mode: é uma réplica de outro servidor de banco de dados?"
                  v-model="servidor.standyMode"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="green darken-1"
                  min-width="100"
                  style="float: right"
                  class="mt-n5 mr-0"
                  @click="testarConexao()"
                  :disabled="!dadosConexaoPreenchidos"
                  :loading="loading"
                >
                  Testar Conexão
                </v-btn>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-connection"
            inline
            class="px-5 py-3"
            style="min-height: 850px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Status de conexão com o servidor
              </div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  type="success"
                  color="green"
                  class="py-1 px-1"
                  v-if="resultadoTesteConexaoServidor"
                >
                  Conexão com o servidor realizada com sucesso.
                </v-alert>
                <v-alert
                  dense
                  outlined
                  color="deep-orange"
                  icon="mdi-alert"
                  class="py-1 px-1"
                  v-else
                >
                  Não foi possível se conectar ao servidor ou o teste ainda não
                  foi realizado.
                  <div v-if="mensagemErroTesteConexaoServidor">
                    Erro: {{ mensagemErroTesteConexaoServidor }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="save()"
            :disabled="!dadosConexaoPreenchidos"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ServidoresBancosDadosOracleService from '@/services/ServidoresBancosDadosOracleService';
import SelectCompany from '@/components/general/SelectCompany.vue';
import routes from '@/store/modules/routes';

export default {
  components: {
    SelectCompany
  },
  data: () => ({
    servidor: {
      id: null,
      nome: '',
      tnsConfig: '',
      standyMode: false,
      username: '',
      password: ''
    },
    showPassword: false,
    loading: false,
    resultadoTesteConexaoServidor: false,
    mensagemErroTesteConexaoServidor: null,
    companyId: null
  }),
  mounted() {
    if (this.servidorId) {
      this.getServer(this.servidorId);
    }
  },
  computed: {
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
    servidorId() {
      return this.$route.params.id;
    },
    dadosConexaoPreenchidos() {
      const { nome, tnsConfig, username, password } = this.servidor;
      return !!(nome && tnsConfig && username && password && this.companyId);
    }
  },
  methods: {
    getServer(id) {
      ServidoresBancosDadosOracleService.getById(id)
        .then((response) => {
          this.servidor = response;
          this.testarConexao();
        })
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error('Erro ao buscar os dados do servidor.', '', {
            position: 'topRight'
          });
        });
    },
    companySelected(company) {
      this.companyId = company.id;
    },
    save() {
      this.loading = true;
      ServidoresBancosDadosOracleService.save({
        ...this.servidor,
        companyId: this.companyId
      })
        .then(() => {
          this.$toast.success('Servidor salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes[
              'ORACLE_SERVIDORES_BANCOS_DADOS_LISTAR'
            ]
          });
        })
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error(
            'Erro ao salvar os dados de conexão com o servidor.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    testarConexao() {
      this.loading = true;
      ServidoresBancosDadosOracleService.testConnection(this.servidor)
        .then(() => {
          this.$toast.success(
            'Conexão com o servidor realizada com sucesso.',
            '',
            { position: 'topRight' }
          );
          this.resultadoTesteConexaoServidor = true;
          this.mensagemErroTesteConexaoServidor = null;
        })
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error('Erro ao testar a conexão com o servidor.', '', {
            position: 'topRight'
          });
          this.resultadoTesteConexaoServidor = false;
          this.mensagemErroTesteConexaoServidor = error.data.error;
        })
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    servidor: {
      handler() {
        this.resultadoTesteConexaoServidor = false;
        this.mensagemErroTesteConexaoServidor = null;
      },
      deep: true
    }
  }
};
</script>
